@import url("https://fonts.googleapis.com/css2?family=Allerta&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  font-family: "Allerta", sans-serif;
  font-weight: 900;
}
* {
  text-shadow: 4px 4px 4px #000000;
}
input::placeholder,
input {
  text-shadow: none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777;
}
